import gsap from 'gsap';

export function enterDeepDive({
  el,
  done,
  isFromLeft = false,
  delay = 0,
  scrollToPosition,
}: {
  el: Element;
  done: () => void;
  isFromLeft?: boolean;
  delay?: number;
  scrollToPosition?: number;
}) {
  const container = el.querySelector('.horizontal-scroll-container');
  const innerEl = el.querySelector('.horizontal-scroll-container .inner');
  const canvas = el.querySelector('.webgl-canvas');
  const tl = gsap.timeline({
    defaults: {
      duration: 0.67,
      ease: 'power4.inOut',
    },
    onComplete: done,
  });

  window.transitionFinished = false;

  // optional scroll to a specific position
  if (scrollToPosition && container) {
    container.scrollTo(scrollToPosition, 0);
  }

  if (window.innerWidth < 800) {
    // Viewport Small
    tl.set(container, {
      position: 'fixed',
      top: 0,
    })
      .fromTo(
        container,
        {
          opacity: 0,
        },
        {
          opacity: 1,
        },
      )
      .add(() => {
        gsap.set(container, {
          position: 'absolute',
        });
        window.transitionFinished = true;
      });
  } else {
    // Viewport Large
    gsap.set(innerEl, { width: '100%' });

    tl.fromTo(
      container,
      {
        x: isFromLeft ? -window.innerWidth : window.innerWidth,
        clipPath: `inset(5% 0% 10% 0%)`,
        scale: 0.95,
        position: 'fixed',
        overflowX: 'hidden',
      },
      {
        x: 0,
        clipPath: `inset(5% 0% 10% 0%)`,
        scale: 0.95,
        position: 'fixed',
        delay: delay || 0,
      },
    )
      .to(
        container,
        {
          clipPath: 'inset(0% 0% 0% 0%)',
          scale: 1,
          position: 'fixed',
        },
        '<52%',
      )
      .add(() => {
        // Set `position` back to `relative` or `static` after animation completes
        gsap.set(container, {
          position: 'absolute',
          x: 'unset',
          overflowX: 'scroll',
        });
        gsap.set(innerEl, { width: 'fit-content' });

        window.transitionFinished = true;
      })
      .add(() => {});
  }
}
