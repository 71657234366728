import gsap from 'gsap';

export function leaveDeepDive({
  el,
  done,
  isGoingLeft = false,
}: {
  el: Element;
  done: () => void;
  isGoingLeft?: boolean;
}) {
  const container = el.querySelector('.horizontal-scroll-container');

  const tl = gsap.timeline({
    defaults: {
      duration: 0.5,
      ease: 'power4.inOut',
    },
    onComplete: done,
  });

  gsap.set(container, { opacity: 1, zIndex: 100 });

  if (window.innerWidth < 800) {
    tl.to(container, {
      opacity: 0,
    });
  } else {
    tl.fromTo(
      container,
      {
        // delay: 0.34,
        x: 0,
        clipPath: 'inset(0% 0% 0% 0%)',
        scale: 1,
        position: 'fixed',
      },
      {
        x: 0,
        clipPath: 'inset(5% 0% 10% 0%)',
        scale: 0.95,
        position: 'fixed',
      },
    )
      .to(container, {
        x: isGoingLeft ? -window.innerWidth : window.innerWidth,
        position: 'fixed',
      })
      .add(() => {
        // Set `position` back to `relative` or `static` after animation completes
        gsap.set(container, { position: 'relative' });
      });
  }
}
