import gsap from 'gsap';

export function leaveDefault(el: Element, done: () => void) {
  const isVs = window.innerWidth < 800;

  gsap.fromTo(
    el,
    {
      zIndex: -1,
    },
    {
      zIndex: -1,
      duration: 0.34,
      delay: isVs ? 0 : 0.67,
      onComplete: done,
    },
  );
}
