import { default as indexYy8SidDWaAMeta } from "/opt/render/project/src/pages/index.vue?macro=true";
import { default as _91id_93aa32qm9dYQMeta } from "/opt/render/project/src/pages/jobs/[id].vue?macro=true";
import { default as _91id_93kyqPp0AenUMeta } from "/opt/render/project/src/pages/projects/[id].vue?macro=true";
import { default as quick_45launchfTKD674t8yMeta } from "/opt/render/project/src/pages/quick-launch.vue?macro=true";
import { default as teamXOAaEWhSevMeta } from "/opt/render/project/src/pages/team.vue?macro=true";
import { default as technologiemfoPG64LpUMeta } from "/opt/render/project/src/pages/technologie.vue?macro=true";
import { default as ux_45uipjULhkC5RfMeta } from "/opt/render/project/src/pages/ux-ui.vue?macro=true";
import { default as webagentur_45schweiz9SY1gWn2QaMeta } from "/opt/render/project/src/pages/webagentur-schweiz.vue?macro=true";
import { default as webappsgpk5tmquK3Meta } from "/opt/render/project/src/pages/webapps.vue?macro=true";
import { default as workkIy1SRbp3FMeta } from "/opt/render/project/src/pages/work.vue?macro=true";
import { default as component_45stubzFO8P7pzWFMeta } from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubzFO8P7pzWF } from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index___de",
    path: "/",
    meta: indexYy8SidDWaAMeta || {},
    component: () => import("/opt/render/project/src/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexYy8SidDWaAMeta || {},
    component: () => import("/opt/render/project/src/pages/index.vue")
  },
  {
    name: "jobs-id___de",
    path: "/jobs/:id()",
    meta: _91id_93aa32qm9dYQMeta || {},
    component: () => import("/opt/render/project/src/pages/jobs/[id].vue")
  },
  {
    name: "jobs-id___en",
    path: "/en/jobs/:id()",
    meta: _91id_93aa32qm9dYQMeta || {},
    component: () => import("/opt/render/project/src/pages/jobs/[id].vue")
  },
  {
    name: "projects-id___de",
    path: "/projects/:id()",
    meta: _91id_93kyqPp0AenUMeta || {},
    component: () => import("/opt/render/project/src/pages/projects/[id].vue")
  },
  {
    name: "projects-id___en",
    path: "/en/projects/:id()",
    meta: _91id_93kyqPp0AenUMeta || {},
    component: () => import("/opt/render/project/src/pages/projects/[id].vue")
  },
  {
    name: "quick-launch___de",
    path: "/quick-launch",
    meta: quick_45launchfTKD674t8yMeta || {},
    component: () => import("/opt/render/project/src/pages/quick-launch.vue")
  },
  {
    name: "quick-launch___en",
    path: "/en/quick-launch",
    meta: quick_45launchfTKD674t8yMeta || {},
    component: () => import("/opt/render/project/src/pages/quick-launch.vue")
  },
  {
    name: "team___de",
    path: "/team",
    meta: teamXOAaEWhSevMeta || {},
    component: () => import("/opt/render/project/src/pages/team.vue")
  },
  {
    name: "team___en",
    path: "/en/team",
    meta: teamXOAaEWhSevMeta || {},
    component: () => import("/opt/render/project/src/pages/team.vue")
  },
  {
    name: "technologie___de",
    path: "/technologie",
    meta: technologiemfoPG64LpUMeta || {},
    component: () => import("/opt/render/project/src/pages/technologie.vue")
  },
  {
    name: "technologie___en",
    path: "/en/technologie",
    meta: technologiemfoPG64LpUMeta || {},
    component: () => import("/opt/render/project/src/pages/technologie.vue")
  },
  {
    name: "ux-ui___de",
    path: "/ux-ui",
    meta: ux_45uipjULhkC5RfMeta || {},
    component: () => import("/opt/render/project/src/pages/ux-ui.vue")
  },
  {
    name: "ux-ui___en",
    path: "/en/ux-ui",
    meta: ux_45uipjULhkC5RfMeta || {},
    component: () => import("/opt/render/project/src/pages/ux-ui.vue")
  },
  {
    name: "webagentur-schweiz___de",
    path: "/webagentur-schweiz",
    meta: webagentur_45schweiz9SY1gWn2QaMeta || {},
    component: () => import("/opt/render/project/src/pages/webagentur-schweiz.vue")
  },
  {
    name: "webagentur-schweiz___en",
    path: "/en/webagentur-schweiz",
    meta: webagentur_45schweiz9SY1gWn2QaMeta || {},
    component: () => import("/opt/render/project/src/pages/webagentur-schweiz.vue")
  },
  {
    name: "webapps___de",
    path: "/webapps",
    meta: webappsgpk5tmquK3Meta || {},
    component: () => import("/opt/render/project/src/pages/webapps.vue")
  },
  {
    name: "webapps___en",
    path: "/en/webapps",
    meta: webappsgpk5tmquK3Meta || {},
    component: () => import("/opt/render/project/src/pages/webapps.vue")
  },
  {
    name: "work___de",
    path: "/work",
    meta: workkIy1SRbp3FMeta || {},
    component: () => import("/opt/render/project/src/pages/work.vue")
  },
  {
    name: "work___en",
    path: "/en/work",
    meta: workkIy1SRbp3FMeta || {},
    component: () => import("/opt/render/project/src/pages/work.vue")
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de-CH-sitemap.xml",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/en-CH-sitemap.xml",
    component: component_45stubzFO8P7pzWF
  }
]